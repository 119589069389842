var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"payment-container"},[_c('div',{staticClass:"details-visit"},[_c('h2',[_vm._v(_vm._s(_vm.$t("payment.appointment-details")))]),_c('div',[_c('img',{staticClass:"profile_img",attrs:{"src":_vm.baseUrl + _vm.regularPayment.therapist.photo}}),_c('h2',[_vm._v(" "+_vm._s(_vm.regularPayment.therapist.user.first_name + " " + _vm.regularPayment.therapist.user.last_name)+" ")]),_c('h4',{staticClass:"specialization"},[_vm._v(" "+_vm._s(_vm.regularPayment.therapist.specializations[0])+" ")]),_c('h4',[_vm._v(_vm._s(_vm.visitDate))]),_c('h4',[_vm._v(_vm._s(_vm.visitTime))])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.type == 'regular' && _vm.loaded)?_c('div',{staticClass:"price-tag"},[_c('h4',[_vm._v(_vm._s(_vm.$t("payment.total-price")))]),_c('h4',[_vm._v(_vm._s(_vm.visitPrice + " " + _vm.therapistCurrency))])]):_vm._e()])],1),_c('div',{staticClass:"payment-details"},[_c('h2',[_vm._v(_vm._s(_vm.$t("payment.payment-option")))]),_c('div',{staticClass:"box"},[_c('div',{staticClass:"option",class:{ checked: _vm.type === 'regular' },on:{"click":function($event){_vm.type = 'regular'}}},[_c('img',{staticClass:"check",attrs:{"src":_vm.type !== 'regular'
              ? require("@/assets/unchecked.png")
              : require("@/assets/checked.png")}}),_c('h5',[_vm._v(_vm._s(_vm.$t("payment.regular")))])]),_c('div',{staticClass:"option",class:{
          checked: _vm.type === 'subscription',
          disabled: _vm.patientPackages.length == 0,
        },on:{"click":function () {
            if (_vm.patientPackages.length > 0) { _vm.type = 'subscription'; }
          }}},[_c('img',{staticClass:"check",attrs:{"src":_vm.type !== 'subscription'
              ? require("@/assets/unchecked.png")
              : require("@/assets/checked.png")}}),_c('h5',[_vm._v(_vm._s(_vm.$t("payment.subscription")))])])]),(_vm.type === 'subscription')?_c('div',{staticClass:"packages"},_vm._l((_vm.patientPackages),function(pack){return _c('div',{key:pack.id,staticClass:"package",class:{ active: pack.id == _vm.package_id },on:{"click":function () {
            _vm.package_id = pack.id;
            _vm.canPayPackage = true;
          }}},[_c('div',[_c('img',{staticClass:"check",attrs:{"src":_vm.package_id === pack.id
                ? require("@/assets/checked.png")
                : require("@/assets/unchecked.png")}}),_c('span',[_vm._v(" "+_vm._s(pack.name)+" ")])])])}),0):_c('div',{staticClass:"TPay"},[(_vm.therapistCurrency == 'PLN')?_c('TpayOptions',{attrs:{"therapist_id":_vm.regularPayment.therapist.id},on:{"picked-bank":_vm.loadPickedBank,"service-provider":_vm.loadServiceProvider,"backend-loaded":function($event){_vm.serviceProviderLoaded = true}}}):_vm._e()],1),(_vm.serviceProviderLoaded || _vm.type === 'subscription')?_c('div',{staticClass:"terms-check check",on:{"click":function($event){_vm.termsAccepted = !_vm.termsAccepted}}},[_c('img',{attrs:{"src":_vm.termsAccepted
            ? require("@/assets/checked.png")
            : require("@/assets/unchecked.png")}}),_c('div',{key:_vm.showTermsWarning,staticClass:"terms",class:{ warn: _vm.showTermsWarning }},[_c('p',[_vm._v(" "+_vm._s(_vm.$t("payment.terms1"))+" "),_c('a',{attrs:{"target":"_blank","href":("https://calmsie.ai/termsandconditions?provider=" + _vm.serviceProviderName + "&t=v&adress=" + _vm.serviceProviderAdress + "&taxid=" + _vm.serviceProviderTaxId)}},[_vm._v(_vm._s(_vm.$t("payment.terms-conditions")))]),_vm._v(_vm._s(_vm.$t("payment.terms2"))+" ")])])]):_vm._e(),(_vm.type == 'regular')?_c('h2',[_vm._v(_vm._s(_vm.$t("payment.payment-information")))]):_vm._e(),(_vm.type == 'regular')?_c('div',{staticClass:"invoice-check",on:{"click":function () {
          _vm.wantInvoice = !_vm.wantInvoice;
        }}},[_c('img',{attrs:{"src":_vm.wantInvoice
            ? require("@/assets/unchecked.png")
            : require("@/assets/checked.png"),"alt":""}}),_c('span',[_vm._v(_vm._s(_vm.$t("payment.i-dont-need-an-invoice")))])]):_vm._e(),_c('transition',{attrs:{"name":"invoice"}},[(_vm.wantInvoice && _vm.type == 'regular')?_c('InvoiceForm',{ref:"invoice_form",on:{"submit-form":_vm.updateInvoice}}):_vm._e()],1),_c('div',{staticClass:"buttons"},[_c('button',{on:{"click":function($event){return _vm.$router.go(-1)}}},[_vm._v(" "+_vm._s(_vm.$t("payment.form.step-back"))+" ")]),(_vm.type == 'regular')?_c('button',{class:{ active: _vm.canPayRegular },on:{"click":_vm.proceedToRegularPayment}},[_vm._v(" "+_vm._s(_vm.$t("payment.form.confirm"))+" ")]):_c('button',{class:{ active: _vm.canPayPackage },on:{"click":_vm.proceedToPackagePayment}},[_vm._v(" "+_vm._s(_vm.$t("payment.form.confirm"))+" ")])])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }