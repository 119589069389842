
import Vue from "@/interfaces/vue";
import { Component, Prop } from "vue-property-decorator";
import { get } from "@/api";
import { Bank } from "@/interfaces/payment";

@Component({})
export default class TpayOptions extends Vue {
  @Prop({ default: 0 })
  therapist_id!: number;

  banks: Bank[] = [];
  picked_bankID = 0;
  loaded = false;

  mounted() {
    this.loadTpayBanks();
  }

  async loadTpayBanks() {
    try {
      const { data } = await get.therapistBankGroups(this.therapist_id);
      this.banks = data;
      this.$emit("service-provider", this.banks)
      this.$emit("backend-loaded", true)
      this.loaded = true;
    } catch (error) {
      console.log(error);
    }
  }

  emitPickedBank(bank: Bank) {
    this.picked_bankID = bank.id;
    this.$emit("picked-bank", bank);
  }
}
