
import TpayOptions from "@/components/payments/TpayOptions.vue";
import i18n from "@/i18n";

import {
  Bank,
  Invoice,
  RegularSessionPayment,
  PackageSessionPayment,
  BookingData,
} from "@/interfaces/payment";
import { Package, TherapistVisitType, VisitType } from "@/interfaces/visit";
import { Vue, Component } from "vue-property-decorator";
import { getStartingLocale } from "@/i18n-setup";
import { get, post } from "@/api";
import InvoiceForm from "@/components/payments/Invoice.vue";

@Component({
  components: {
    TpayOptions,
    InvoiceForm,
  },
})
export default class VisitPayment extends Vue {
  type = "regular";
  regularPayment: BookingData = this.$store.state.payment;
  wantInvoice = true;
  isInvoiceValid = false;
  invoice!: Invoice;
  therapistCurrency = "";
  canPayRegular = false;
  canPayPackage = false;
  pickedBank!: Bank;
  visitPrice = 0;
  package_id = 0;
  patientPackages: Package[] = [];
  termsAccepted = false;
  showTermsWarning = false;
  banks: Bank[] = [];
  loaded = false;

  serviceProviderName = "";
  serviceProviderAdress = "";
  serviceProviderTaxId = "";
  serviceProviderLoaded = false;

  baseUrl = process.env.VUE_APP_TARGET;

  mounted() {
    this.getTherapistDates();
    this.getPatientPackages();
  }

  get visitDate(): string {
    const date = new Date(this.regularPayment.pickedDate);
    const month = new Intl.DateTimeFormat(i18n.locale, {
      month: "long",
    }).format(date);
    const weekday = new Intl.DateTimeFormat(i18n.locale, {
      weekday: "long",
    }).format(date);
    return month + " " + date.getDate() + ", " + weekday;
  }

  get visitTime(): string {
    return new Intl.DateTimeFormat(getStartingLocale(), {
      hour: "numeric",
      minute: "numeric",
    }).format(this.regularPayment.pickedDate);
  }

  async getTherapistDates() {
    try {
      const { data } = await get.therapistDates(
        this.regularPayment.therapist.id
      );

      data.visit_types.forEach((visit: TherapistVisitType) => {
        if (visit.id === this.regularPayment.visit.id) {
          this.visitPrice = visit.price;
        }
      });
      this.therapistCurrency = data.accepted_currency;
      this.loaded = true;
    } catch (error) {
      console.log(error);
    }
  }

  async getPatientPackages() {
    try {
      const { data } = await get.patientPackages();
      this.patientPackages = data;
    } catch (error) {
      console.log(error);
    }
  }

  loadPickedBank(bank: Bank) {
    this.pickedBank = bank;
    this.canPayRegular = true;
  }

  loadServiceProvider(banks: any) {
    this.serviceProviderName = banks.company.name as string;
    this.serviceProviderAdress = `${banks.company.city} ${banks.company.postal_code} ${banks.company.street} ${banks.company.building}/${banks.company.apartment}`;
    this.serviceProviderTaxId = banks.company.tax_id;
  }

  async proceedToRegularPayment() {
    if (!this.termsAccepted) {
      this.showTermsWarning = true;
      return;
    }
    if (this.wantInvoice) {
      const refs = this.$refs.invoice_form as HTMLFormElement;
      const isInvoiceValid = refs.validateFields();
      if (!isInvoiceValid) return;
    }

    const payload: RegularSessionPayment = {
      therapist: this.regularPayment.therapist.id,
      date: this.regularPayment.pickedDate,
      therapist_visit_type: this.regularPayment.visit.id,
      displayed_price: this.visitPrice,
      meeting_type: "ON",
      bank_group: this.pickedBank.id,
      send_invoice: this.wantInvoice,
      invoice_data: this.invoice
    };

    if (this.regularPayment.additional) {
      Object.assign(payload, { patient_note: this.regularPayment.additional });
    }

    try {
      const { data } = await post.bookSession(payload);
      this.saveTherapistData();
      window.location.href = data.redirection_link;
    } catch (error) {
      this.$router.push("payment/checkout/failure");
    }
  }

  async proceedToPackagePayment() {
    if (!this.termsAccepted) {
      this.showTermsWarning = true;
      return;
    }

    const payload: PackageSessionPayment = {
      therapist: this.regularPayment.therapist.id,
      date: this.regularPayment.pickedDate,
      therapist_visit_type: this.regularPayment.visit.id,
      displayed_price: this.visitPrice,
      covered_by: this.package_id,
    };

    if (this.regularPayment.additional) {
      Object.assign(payload, { patient_note: this.regularPayment.additional });
    }
    
    this.saveTherapistData();
    try {
      await post.bookSession(payload);
      this.$router.push("payment/checkout/success");
    } catch (error) {
      this.$router.push("payment/checkout/failure");
    }
  }

  updateInvoice(payload: Invoice) {
    this.invoice = payload;
  }

  saveTherapistData() {
    const bookedSessionData = {
      therapistPhoto: this.regularPayment.therapist.photo,
      therapistFirstname: this.regularPayment.therapist.user.first_name,
      therapistLastname: this.regularPayment.therapist.user.last_name,
      sessionDate: this.regularPayment.pickedDate.toString(),
      additionalInfo: this.regularPayment.additional,
    };
    sessionStorage.setItem(
      "booked_session_data",
      JSON.stringify(bookedSessionData)
    );
  }
}
